import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, FormControl, InputLabel, ListItem, ListItemIcon, ListItemText, MenuItem, Select} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddQueueElementForm from "./AddQueueElementForm";
import DialogContent from "@mui/material/DialogContent";
import axios from "axios";
import {TOOLPRESS_API} from "../config";
import {questionTypes} from "./QueuePin";
import {pleshDark} from "../theme/pleshDark";

export default function AddQueueElement({handleAddElement, reorderedQueue}) {
    const [open, setOpen] = React.useState(false);
    const [elementType, setElementType] = React.useState('');

    const [domanda, setDomanda] = useState('');

    const [risposte, setRisposte] = useState([])
    const [corretta, setCorretta] = useState('')
    const [hideResult, setHideResult] = useState(false)

    const [conferenza, setConferenza] = useState('')

    const [colore, setColore] = useState('#ffffff29')

    const handleSetDomanda = (domanda) => {
        setDomanda(domanda)
    }

    const handleSetCorretta = (corretta) => {
        setCorretta(corretta)
    }

    const handleSetRisposte = (risposte) => {
        setRisposte([...risposte])
    }

    const handleHideResult = (hideResult) => {
        setHideResult(hideResult)
    }

    const handleSetColore = (col) => {
        setColore(col)
    }

    const handleSetConferenza = (conf) => {
        setConferenza(conf)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event) => {
        setElementType(event.target.value)
    }

    const handleAdd = async (e) => {
        e.preventDefault()
        if (elementType === 'toolpress') {
            await axios.get(`${TOOLPRESS_API}/conference/${conferenza}`)
                .then(r => {
                    addElement()
                })
                .catch(e => {
                    alert('La conferenza non esiste')
                })
        } else {
            await addElement()
        }
    }

    const addElement = async () => {
        let positions = reorderedQueue.map(el => el.position)
        let position = Math.max(...positions) + 1
        if (domanda !== '' && elementType !== '') {
            let el = {
                domanda: domanda,
                tipo: elementType,
                timestamp: new Date().getTime(),
                position: position
            }
            if (elementType === 'quiz') {
                el.risposte = risposte
                el.corretta = corretta
                el.hideResult = hideResult

                setRisposte([])
                setCorretta('')
                setHideResult(false)
            } else if (elementType === 'poll' || elementType === 'multiPoll' || elementType === 'reorder'
                || elementType === 'color' || elementType === 'links') {
                el.risposte = risposte
                setRisposte([])
                if (elementType === 'color') {
                    el.color = risposte[0]?.color
                    setColore(null)
                }
            } else if (elementType === 'sendPhoto') {
                /** se hideResult è a True le foto devono essere approvate manualmente da qualcuno */
                el.hideResult = hideResult
                setHideResult(false)
            } else if (elementType === 'clickTo' || elementType === 'divider') {
                el.color = colore
                setColore(null)
            } else if (elementType === 'toolpress') {
                el.conferenza = conferenza
                setConferenza('')
            } else if (elementType === 'audio') {
                el.progressivoDomanda = 0
            }
            setDomanda('')
            setElementType('')
            handleAddElement(el)
            handleClose();
        }
    }

    const AddEvent = () => (
        <Button variant={'contained'} onClick={handleClickOpen} aria-label={'Add'} color={"primary"}
                sx={{borderRadius: '0.2rem'}} startIcon={<AddIcon/>}>
            Aggiungi una domanda
        </Button>
    )

    return (
        <Box>
            <AddEvent/>
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Aggiungi una domanda</DialogTitle>
                <form onSubmit={handleAdd}>
                    <DialogContent>
                        <Box>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={elementType}
                                    label="Tipo"
                                    onChange={handleChange}
                                >
                                    {
                                        Object.values(questionTypes)
                                            .map(({id, icon, text}) => (
                                                <MenuItem key={id} value={id}>
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            {icon}
                                                        </ListItemIcon>
                                                        <ListItemText>
                                                            {text}
                                                        </ListItemText>
                                                    </ListItem>
                                                </MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <AddQueueElementForm
                            type={elementType}
                            handleSetDomanda={handleSetDomanda}
                            handleSetColore={handleSetColore}
                            handleSetRisposte={handleSetRisposte}
                            handleSetCorretta={handleSetCorretta}
                            handleHideResult={handleHideResult}
                            handleSetConferenza={handleSetConferenza}
                            risposte={risposte}
                            corretta={corretta}
                            hideResult={hideResult}
                            colore={colore}
                            conferenza={conferenza}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancella</Button>
                        <Button type={'submit'} variant={'contained'} sx={{borderRadius:'0.2rem'}}>Aggiungi</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </Box>
    );
}
