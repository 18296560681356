import React, {useEffect, useState} from "react";
import {
    Badge,
    Box,
    Chip,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import {
    ContentCopy,
    DragIndicator,
    Edit,
    FiberManualRecord,
    Gavel,
    MoreVert,
    OpenInNew,
    PlayArrow,
    PushPin,
    Stop,
    TextFields,
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import QueuePin from "./QueuePin";
import RemoveQueueElementButton from "./RemoveQueueElementButton";
import EditQueueElement from "./EditQueueElement";
import {Link, useParams} from "react-router-dom";
import {Draggable} from "react-beautiful-dnd";
import ResetQueueElementResultButton from "./ResetQueueElementResultButton";
import Tooltip from "@mui/material/Tooltip";
import {useCollection} from "react-firebase-hooks/firestore";
import {firestore} from "../firebase/clientApp";
import {pleshDark} from "../theme/pleshDark";
import {AvatarGroup} from "@mui/lab";
import Avatar from "@mui/material/Avatar";
import StyledBadge from "./shared/StyledBadge";

export default function QueueElement({
                                         element,
                                         handleRemoveElement,
                                         handlePlayElement,
                                         elementPlaying,
                                         playing,
                                         index,
                                         pinElement
                                     }) {
    const {id} = useParams()
    const url = 'users/plesh/events/' + id
    const [numInteractions, numInteractionsLoading, numInteractionsError] = useCollection(firestore.collection(`${url}/queue/${element.id}/answers`), {})

    const [play, setPlay] = useState(false)
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    useEffect(() => {
        if (element.id === elementPlaying) {
            setPlay(true)
        } else {
            setPlay(false)
        }
    }, [elementPlaying])

    function updatePlay() {
        handlePlayElement(element.id)
    }

    const handleOpenEditDialog = () => {
        setAnchorEl(null)
        setOpen(true)
    }

    const handleCloseEditDialog = () => {
        setOpen(false)
    }

    const handleCopyId = (elementId) => {
        navigator.clipboard.writeText(elementId)
            .then(() => window.alert('ID copiato negli appunti'))
    }

    async function handleChangeMainColor(questionId, color) {
        await firestore.doc(`users/plesh/events/${id}/queue/${questionId}`).update({
            color: color,
        })
            .then(() => {})
            .finally(() => {})
    }

    async function handleChangeQuizResultVisibility(questionId, visiblity) {
        await firestore.doc(`users/plesh/events/${id}/queue/${questionId}`).update({
            hideResult: visiblity,
        })
            .then(() => {})
            .finally(() => {})
    }

    return (
        <Draggable key={element.id} draggableId={element.id} index={index}>
            {(provider) => (
                <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{xs: 'start', sm: 'center'}}
                       justifyContent={'space-between'} sx={{
                    background: element.tipo === 'divider' ? "transparent"
                        : element.id === elementPlaying ?
                            //element.tipo === 'color' ? element.color :
                            '#414272' : '#1C1C3D',
                    borderRadius: '0.5rem',
                    //border: element.tipo === 'divider' ? '1px solid '+'rgba(76,76,126,0.30)' : null,
                    my: 1,
                    width: '100%',
                    px: 1,
                    py: 0.7
                }}
                       ref={provider.innerRef}
                       {...provider.draggableProps}
                >
                    <Stack direction={{xs: 'column', sm: 'row'}} alignItems={{xs: 'start', sm: 'center'}}
                           sx={{width: {xs: '100%', sm: 'inherit'}}}
                           spacing={2}
                    >
                        {/*{(!element.pinned) &&
                            <Box pl={1}>
                                <Chip label={index + 1} />
                            </Box>}*/}
                        <Box sx={{pr: 1, alignSelf: 'center', display: element.pinned ? 'none' : 'block'}}
                             {...provider.dragHandleProps}
                        >
                            <DragIndicator/>
                        </Box>
                        {element.tipo !== 'divider' &&
                            <Box sx={{alignSelf: 'center'}}>
                                {/*<Badge badgeContent={numInteractions?.size}
                                       color={"warning"}
                                       anchorOrigin={{
                                           vertical: 'top',
                                           horizontal: 'left',
                                       }}
                                >*/}
                                <QueuePin tipo={element.tipo} color={element.color}/>
                                {/*</Badge>*/}
                            </Box>}
                        {
                            element.tipo === 'divider' ?
                                <Stack width={'100%'}>
                                    <Divider variant={'fullWidth'}
                                             textAlign="center"
                                             children={<Chip label={element.domanda}
                                                             sx={{
                                                                 color: pleshDark.palette.getContrastText(element?.color || '#000000'),
                                                                 background: element.color || 'default'
                                                             }}
                                             />}
                                    />
                                </Stack>
                                :
                                <Badge badgeContent={numInteractions?.size}
                                       max={99999}
                                    //color={"error"}
                                       anchorOrigin={{
                                           vertical: 'top',
                                           horizontal: 'right',
                                       }}
                                       componentsProps={{
                                           root: {
                                               style: {
                                                   width: 'fit-content',
                                                   maxWidth: '75%'
                                               }
                                           },
                                           badge: {
                                               style: {
                                                   marginTop: '-4px',
                                                   marginRight: '-8px',
                                                   background: pleshDark.palette.accent.main,
                                                   color: pleshDark.palette.secondary.main,
                                                   //height: '16px'
                                               }
                                           }
                                       }}
                                >
                                    <Typography sx={{py: 1}} textAlign={"left"}>{element.domanda}</Typography>
                                </Badge>
                        }
                        {
                            element.tipo === 'quiz' &&
                            <Tooltip
                                title={element.hideResult === true ? "Risultato nascosto all'utente" : "Risultato visibile all'utente"}
                                children={
                                    <IconButton onClick={() => handleChangeQuizResultVisibility(element.id, !element.hideResult)}>
                                        {element.hideResult === true
                                            ? <VisibilityOff fontSize={'small'}/>
                                            : <Visibility fontSize={'small'}/>}
                                    </IconButton>}/>
                        }
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                        {
                            element.tipo === 'color' &&
                            <Tooltip
                                title={"Cambia il colore"}
                                children={
                                    <AvatarGroup max={20} spacing={element.risposte.length > 6 ? "small" : "medium"}
                                                 sx={{".MuiAvatar-root": {border: '2px solid ' + (element.id === elementPlaying ? '#414272' : '#1C1C3D')}}}>
                                        {element.risposte?.map((r, i) => (
                                            r.color === element.color ?
                                                <StyledBadge
                                                    key={i}
                                                    overlap="circular"
                                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                                    variant="dot"
                                                >
                                                    <Avatar>
                                                        <IconButton size={'small'} disabled>
                                                            <FiberManualRecord fontSize={'large'}
                                                                               sx={{color: r.color}}/>
                                                        </IconButton>
                                                    </Avatar>
                                                </StyledBadge> :
                                                <Avatar key={i}>
                                                    <IconButton size={'small'}
                                                                onClick={() => handleChangeMainColor(element.id, r.color)}>
                                                        <FiberManualRecord fontSize={'large'} sx={{color: r.color}}/>
                                                    </IconButton>
                                                </Avatar>
                                        ))}
                                    </AvatarGroup>
                                }/>}
                        <Box>
                            <IconButton size={'large'} onClick={handleMenu}>
                                <MoreVert fontSize={'inherit'}/>
                            </IconButton>
                            <Menu open={openMenu} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                                {element.id !== elementPlaying &&
                                    <RemoveQueueElementButton id={element.id}
                                                              handleRemoveElement={handleRemoveElement}/>
                                }
                                {(element.id !== elementPlaying || element.tipo === 'color') &&
                                    <MenuItem onClick={handleOpenEditDialog}>
                                        <ListItemIcon>
                                            <Edit/>
                                        </ListItemIcon>
                                        <ListItemText>Modifica</ListItemText>
                                    </MenuItem>
                                }
                                <MenuItem onClick={() => pinElement(element.id)}>
                                    <ListItemIcon>
                                        <PushPin/>
                                    </ListItemIcon>
                                    <ListItemText>{element.pinned ? "Rimuovi da home" : "Fissa in home"}</ListItemText>
                                </MenuItem>
                                {(element.tipo !== 'altro' && element.tipo !== 'links') &&
                                    <MenuItem component={["toolpress"].includes(element.tipo) ? 'a' : Link}
                                              to={'/control/' + id + '/domanda/' + element.id}
                                              href={`https://control.toolpress.app/conferenza/${element.conferenza}`}
                                              target={'_blank'}>
                                        <ListItemIcon>
                                            <OpenInNew/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {["toolpress"].includes(element.tipo) ? "Vai a Toolpress" : "Grafico risultati"}
                                        </ListItemText>
                                    </MenuItem>}
                                <MenuItem component={Link} target={'_blank'}
                                          to={'/control/' + id + '/domanda/' + element.id + '?question=true'}>
                                    <ListItemIcon>
                                        <TextFields/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        Testo domanda
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleCopyId(element.id)}>
                                    <ListItemIcon>
                                        <ContentCopy/>
                                    </ListItemIcon>
                                    <ListItemText>Copia ID domanda</ListItemText>
                                </MenuItem>
                                {
                                    ["sendPhoto", "poll", "quiz", "multiPoll", "wordcloud", "reserveSpot"].includes(element.tipo) &&
                                    <MenuItem component={Link} target={'_blank'}
                                              to={'/control/' + id + '/domanda/' + element.id + '?control=true'}>
                                        <ListItemIcon>
                                            <Gavel/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Moderazione
                                        </ListItemText>
                                    </MenuItem>
                                }
                                {element.id !== elementPlaying
                                    && !["divider", "toolpress", "links"].includes(element.tipo)
                                    && <>
                                        <Divider/>
                                        <ResetQueueElementResultButton elementId={element.id}
                                                                       handleCloseMenu={() => setAnchorEl(null)}/>
                                    </>}
                            </Menu>
                            <EditQueueElement handleCloseEditDialog={handleCloseEditDialog}
                                              element={element} open={open}/>
                        </Box>


                        {playing &&
                            <Box>
                                {/*<Badge badgeContent={numInteractions?.size}
                                       color={"warning"}
                                       anchorOrigin={{
                                           vertical: 'top',
                                           horizontal: 'right',
                                       }}
                                >*/}
                                <IconButton disabled={element.tipo === 'divider'}
                                            onClick={updatePlay}
                                            edge="end" aria-label="play" size={'large'}>
                                    {
                                        element.id === elementPlaying ?
                                            <Stop fontSize={'inherit'}/>
                                            :
                                            <PlayArrow fontSize={'inherit'}/>
                                    }
                                </IconButton>
                                {/*</Badge>*/}
                            </Box>
                        }
                    </Stack>
                </Stack>)}
        </Draggable>
    )
}
