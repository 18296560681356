import React from "react";
import {Box, Button, Divider, Stack, Typography} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Pause, PlayArrow} from "@mui/icons-material";
import {AudioPlayer} from "./AudioVisual/AudioPlayer";
import {isArray} from "lodash";

export const AudioVisual = ({result, domanda}) => {
    console.log("result:",result)
    const [current, setCurrent] = React.useState("")
    const theme = useTheme()

    const handlePlayPause = (r) => {
        if (current === r) {
            setCurrent('')
        } else {
            setCurrent(r.filename)
        }
    }

    return (
        <Box sx={{position: 'relative', minHeight: '100vh', pb:22}}>
            <Box sx={{p: 3}}>
                <Stack spacing={2}>
                    {(result && !!result.data && isArray(result?.data))
                        ? (result?.data || []).sort((a, b) => a.createdAt - b.createdAt).map((r, i) =>
                        <Box key={i}>
                            <Stack key={i} direction={'row'} spacing={4}>
                                <Box sx={{minWidth:'3rem'}}>
                                    <Typography variant={'h5'}>
                                        {i + 1}.
                                    </Typography>
                                    <Box sx={{width:'100%', height:'4px', background:theme.palette.primary.main}}/>
                                </Box>
                                <Box>
                                    <Typography variant={'h5'}>
                                        {r.filename}
                                    </Typography>
                                    <Typography variant={'h6'}>
                                        Trascrizione
                                    </Typography>
                                    <Typography>
                                        {r.transcript}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Box sx={{pt:2}}>
                                <Button onClick={() => handlePlayPause(r)} disabled={current === r.filename} variant={'contained'} startIcon={current === r.filename ? <Pause/> : <PlayArrow/>}>
                                    {current === r.filename ? "In riproduzione" : "Riproduci"}
                                </Button>
                            </Box>
                            {i < result.data.length - 1 && <Divider sx={{mt:2}}/>}
                        </Box>
                    ) : null}
                </Stack>
            </Box>
            <AudioPlayer domanda={domanda} current={current} handlePlayPause={handlePlayPause}/>
        </Box>
    )
}